<template>
  <v-container class="forgot-password" fill-height fluid>
    <v-row align="center" justify="center">
      <v-col></v-col>
      <v-col cols="12" sm="3">
        <v-img :src="url+'icons/logo-d+.png'" :height="32" :max-width="200" contain />
        <v-card class="forgot-password-panel elevation-0" :width="width" max-width="100%">
          <v-card-text v-if="!resetSent">
            <v-text-field label="Email" v-model="email" class="login-fields" light solo></v-text-field>
          </v-card-text>
          <v-card-text v-else>
            A password reset email has been sent to: {{ email }}
          </v-card-text>
          <v-card-actions v-if="!resetSent" class="pa-0">
            <v-spacer/>
            <v-btn @click="forgotPassword" text>
             Reset Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-spacer/>
  </v-container>
</template>

<script>
import { Api } from '../js/api';

export default {
  name: 'ForgotPassword',
  components: {
  },
  data: () => ({
    email: '',
    resetSent: false
  }),
  computed: {
    url () {
      return process.env.VUE_APP_CLIENT_PROTOCOL + process.env.VUE_APP_CLIENT_URL;
    }
  },
  methods: {
    forgotPassword: function () {
      let self = this;
      let data = {
        email: this.email
      }
      Api.post('Auth/forgotPassword', data).then(function () {
        self.resetSent = true
      })
    },
  },
  mounted () {
  }
}
</script>
<style lang="scss">
.forgot-password {
  height: 100vh;
}
.forgot-password-panel {
  background-color: inherit!important;
}
</style>
